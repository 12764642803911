import {RestService} from "@/services/rest/RestService";
import {Pp2CNKFilterAPIApi, Pp2ProductAPIApi} from "@/api/pharma-pim-pp2";
import {
    IPrinsLaboRestDto,
    IPrinsPriceOverviewResultsRestDto, MultilingualFieldRestDto,
    PagedPp2ProductOverviewResultsRestDto,
    Pp2ProductDetailRestDto,
    Pp2UserCnkFilterRestDto,
} from "@/api/pharma-pim-pp2/models";
import {PimProductsCriteriaModel} from "@/models/criteria/PimProductsCriteriaModel";
import {PimProductsPagingModel} from "@/models/paging/PimProductsPagingModel";
import {Pp2ProductsCriteriaRestDtoModel} from "@/models/api/pharma-pim-pp2/Pp2ProductsCriteriaRestDtoModel";
import {downloadBlobAsFile} from "@/helpers/functions/file";
import {AxiosResponse} from "axios";
import {convertProductCodesListToArray, productCodesArrayIsValid} from "@/helpers/functions/products";
import {DpException} from "@/exception/DpException";
import {PhotoRestService} from "@/services/rest/photo/PhotoRestService";

export class PimProductsRestService extends RestService {

    private apiService: Pp2ProductAPIApi;
    private cnkFilterApiService: Pp2CNKFilterAPIApi;

    protected constructor() {
        super();

        this.apiService = new Pp2ProductAPIApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().pimUrl);
        this.cnkFilterApiService = new Pp2CNKFilterAPIApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().pimUrl);
    }

    public async getUserCnkFilter(): Promise<string> {
        const response = await this.cnkFilterApiService
            .getPp2UserCnkFilter();
        const dto = response.data as Pp2UserCnkFilterRestDto;
        if (!dto.cnk_codes) {
            return '';
        }
        return dto.cnk_codes.join(', ');
    }

    // todo productCodesArrayIsValid oproepen bij de put
    public async updateUserCnkFilter(cnkCodesAsString : string): Promise<void> {
        const cnkCodesAsArray = convertProductCodesListToArray(cnkCodesAsString);
        if (!productCodesArrayIsValid(cnkCodesAsArray)) {
            throw [new DpException('Een CNK code mag enkel getallen bevatten.')];
        }
        const dto = {
            cnk_codes: cnkCodesAsArray,
        } as Pp2UserCnkFilterRestDto;
        this.cnkFilterApiService.updatePp2UserCnkFilter(dto);
    }

    public async findPimProductOverview(products_criteria: PimProductsCriteriaModel, products_paging: PimProductsPagingModel): Promise<PagedPp2ProductOverviewResultsRestDto> {
        const pp2_products_criteria = new Pp2ProductsCriteriaRestDtoModel(
            products_criteria,
            products_paging,
            undefined
        );
        const response = await this.apiService
            .findPp2ProductsByPp2ProductsCriteriaRestDto(pp2_products_criteria);

        await PhotoRestService.getInstance().fillPimProductImageUrls(response.data.results);

        return response.data;
    }

    public async exportPimProductOverviewAsCsv(products_criteria: PimProductsCriteriaModel, filename: string = 'pim_product_overview.csv'): Promise<void> {
        const pp2_products_criteria = new Pp2ProductsCriteriaRestDtoModel(
            products_criteria,
            undefined,
            'text/csv'
        );
        const response: AxiosResponse<any, any> = await this.apiService
            .findPp2ProductsByPp2ProductsCriteriaRestDto(pp2_products_criteria);

        downloadBlobAsFile(response.data as Blob, filename);
    }

    public async findPimProductForCnkCode(cnk_code: number): Promise<Pp2ProductDetailRestDto> {
        const response = await this.apiService
            .getPp2ProductForCnkCode(cnk_code);

        if (!response.data.product_info.main_image_url) {
            response.data.product_info.main_image_url = {};
        }

        response.data.product_info.main_image_url.nl = await PhotoRestService.getInstance().getProductImageUrl(response.data.product_info.cnk_code, 'nl');
        response.data.product_info.main_image_url.fr = await PhotoRestService.getInstance().getProductImageUrl(response.data.product_info.cnk_code, 'fr');
        response.data.product_info.main_image_url.de = await PhotoRestService.getInstance().getProductImageUrl(response.data.product_info.cnk_code, 'fr');
        response.data.product_info.main_image_url.en = await PhotoRestService.getInstance().getProductImageUrl(response.data.product_info.cnk_code, 'en');

        return response.data;
    }

    public async findIPrinsProductManufacturer(cnk_code: number): Promise<IPrinsLaboRestDto> {
        const response = await this.apiService
            .getIPrinsProductManufacturer(cnk_code);

        return response.data;
    }

    public async findIPrinsProductPrices(cnk_code: number): Promise<IPrinsPriceOverviewResultsRestDto> {
        const response = await this.apiService
            .getIPrinsProductPrices(cnk_code);

        return response.data;
    }
}
