import {RestService} from "@/services/rest/RestService";
import {ContentPageEndpointsApi} from "@/api/pharma-cms-content-page";
import {ValueEnum} from "@/api/enums/value-enum";
import {PagedContentPageOverviewRestDto} from "@/api/pharma-cms-content-page/models";
import {MoveActionEnum} from "@/models/enum/MoveActionEnum";

export class ContentPageRestService extends RestService {

    private apiService: ContentPageEndpointsApi;

    protected constructor() {
        super();

        this.apiService = new ContentPageEndpointsApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().cmsUrl);
    }

    public async updateIsPublishedOnHome(isPublishedOnHome: boolean, pageId: string): Promise<void> {
        await this.apiService.updateIsPublishedOnHome({value: isPublishedOnHome}, pageId);
    }

    public async updatePageStatusCode(status: ValueEnum, pageId: string): Promise<void> {
        await this.apiService.updatePageStatusCode({value: status}, pageId);
    }

    public async deleteContentPage(pageId: string): Promise<void> {
        await this.apiService.deleteContentPage(pageId);
    }

    public async moveContent(pageId: string, moveAction: MoveActionEnum): Promise<void> {
        switch (moveAction) {
            case MoveActionEnum.TOP:
                await this.apiService.moveContentPageToTop(pageId);
                break;
            case MoveActionEnum.BOTTOM:
                await this.apiService.moveContentPageToBottom(pageId);
                break;
            case MoveActionEnum.UP:
                await this.apiService.moveContentPageUp(pageId);
                break;
            case MoveActionEnum.DOWN:
                await this.apiService.moveContentPageDown(pageId);
                break;
            default:
                throw new Error('Unsupported moveAction to call ' + moveAction);
        }
    }

    public async findContentPages() : Promise<PagedContentPageOverviewRestDto> {
        const response = await this.apiService.findContentPages();

        return response.data;
    }

    public async findPriceCards() : Promise<PagedContentPageOverviewRestDto> {
        const response = await this.apiService.findContentPages(null, null, 'PAGE_PRICE_CARD');

        return response.data;
    }

}
