
import {defineComponent} from "vue";
import {CodeDescriptionRestDto,} from "@/api/pharma-cpc-mgmt/models";
import CatalogBuildStatus from "@/components/layouts/catalog/CatalogBuildStatus.vue";
import {useToast} from "vue-toastification";
import {isEmptyString} from "@/helpers/functions/string";
import {StatusEnum} from "@/api/enums/status-enum";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {UIStateDto} from "@/dtos/UIStateDto";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import {EnumRestService} from "@/services/rest/enum/EnumRestService";
import {EnumClassNameCmsEnum} from "@/models/enum/EnumClassNameCmsEnum";
import {SnippetRestService} from "@/services/rest/csm-snippet/SnippetRestService";
import {SnippetCreateRestDtoModel} from "@/models/api/pharma-cms-snippet/SnippetCreateRestDtoModel";
import SelectTrademarks from "@/components/layouts/catalog/SelectTrademarks.vue";
import SelectCategories from "@/components/layouts/catalog/SelectCategories.vue";
import {CustomerContext} from "@/context/CustomerContext";
import InputCheckboxBoolean from "@/components/UI/InputCheckboxBoolean.vue";
import {SnippetInSlotOverviewRestDto} from "@/api/pharma-cms-snippet/models";
import BaseOffcanvasButtonClose from "@/components/UI/Bootstrap/Offcanvas/BaseOffcanvasButtonClose.vue";
import {IdResponseRestDto} from "@/api/pharma-cms-menu/models";
import InputCheckboxMultiSelect from "@/components/UI/InputCheckboxMultiSelect.vue";
import {InSlotPositionEnum} from "@/api/enums/in-slot-position-enum";
import InputText from "@/components/UI/InputText.vue";
import ProductSelector from "@/components/UI/product/ProductSelector.vue";
import {CatalogProductDetailRestDto, CatalogProductOverviewRestDto} from "@/api/pharma-cpc-product-mgmt/models";
import {CatalogProductsRestService} from "@/services/rest/cpc-mgmt/CatalogProductsRestService";
import {CatalogProductOverviewRestDtoModel} from "@/models/api/pharma-cpc-product-mgmt/CatalogProductOverviewRestDtoModel";

export default defineComponent({
  name: "SnippetMetaData",
  components: {
    ProductSelector,
    InputText,
    InputCheckboxMultiSelect,
    BaseOffcanvasButtonClose,
    SelectCategories,
    SelectTrademarks,
    InputCheckboxBoolean,
    AlertError2,
    CatalogBuildStatus
  },
  props: {
    id: {
      type: String,
    },
    mode: {
      type: String,
    },
  },
  data() {
    return {
      originalPositions: [] as String[],
      selectedPositions: [] as String[],
      catalogCustomerCode: CustomerContext.getCustomerCode(),
      slotPositions: [] as CodeDescriptionRestDto[],
      statusOptions: [
        {code: StatusEnum.PUBLISHED, description: this.$t('published')},
        {code: StatusEnum.NOT_PUBLISHED, description: this.$t('notPublished')}
      ] as CodeDescriptionRestDto[],

      snippetChanged: false,
      toaster: useToast(),
      pageUI: UIStateDto.createWithDefaults(),
      snippet: SnippetCreateRestDtoModel.createWithDefaults(),
      product: null as CatalogProductOverviewRestDto | null,
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {},
  methods: {
    isEmptyString,
    async submitData() {
      this.pageUI
        .clearError()
        .setNotReady();

      try {
        if (this.selectedPositions.length === 0) {
          this.pageUI.setError(this.$t('selectAtLeastOnePosition'));
          return;
        }
        this.snippet.snippet.context.product_code = this.product ? this.product.product_code : null;
        if (this.mode === 'new') {
          this.snippet.in_slot_position = this.selectedPositions[0] as InSlotPositionEnum;
          const idResponse: IdResponseRestDto = await SnippetRestService.getInstance().createSnippet(this.snippet);
          for (let i = 1; i < this.selectedPositions.length; i++) {
            await SnippetRestService.getInstance().addSnippetInSlot(idResponse.id, this.selectedPositions[i]);
          }
        } else {
          if (this.snippet?.snippet) {
            await SnippetRestService.getInstance().updateSnippet(this.snippet.snippet, this.id);

            const newPositions: string[] = this.selectedPositions.filter((value) => {
              return !this.originalPositions.includes(value as string)
            }) as string[];
            const deletedPositions: string[] = this.originalPositions.filter(value => !this.selectedPositions.includes(value as string)) as string[];
            for (const position of newPositions) {
              await SnippetRestService.getInstance().addSnippetInSlot(this.id, position);
            }
            for (const position of deletedPositions) {
              await SnippetRestService.getInstance().deleteSnippetInSlot(this.id, position);
            }
          }
        }
        await this.afterSave();
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
        window.scrollTo(0, 0);
      } finally {
        this.pageUI.setReady();
      }
    },
    async reloadContent() {
      this.pageUI
        .clearError()
        .setNotReady();
      try {
        this.slotPositions = await EnumRestService.getInstance().getCmsEnumValuesByClassname(EnumClassNameCmsEnum.SNIPPET_SLOT_POSITION);

        if (this.mode === 'new') {
          this.snippet = SnippetCreateRestDtoModel.createWithDefaults();
          this.originalPositions = [];
          this.selectedPositions = [];
        } else {
          const snippetInSlot: SnippetInSlotOverviewRestDto = await SnippetRestService.getInstance().getSnippetInSlot(this.id);
          this.snippet = SnippetCreateRestDtoModel.createWithDefaults();
          this.snippet.status_description = snippetInSlot.status.description;
          this.snippet.in_slot_position_description = snippetInSlot.slot_position.description;
          this.snippet.snippet.name = snippetInSlot.name;
          this.snippet.snippet.context.product_code = snippetInSlot.context?.product_code;
          if (snippetInSlot.context?.product_code) {
            const productDetail: CatalogProductDetailRestDto =
              await CatalogProductsRestService.getInstance().getCatalogProductDetail(snippetInSlot.context.product_code, this.$i18n.locale);
            this.product = CatalogProductOverviewRestDtoModel.createWithDefaults();
            this.product.product_code = productDetail.product_code;
            this.product.product_code_alias = productDetail.product_code_alias;
            this.product.product_source_code = productDetail.product_source_code;
            this.product.main_image_url = productDetail.main_image_url;
            this.product.product_info = productDetail.product_info;
            this.product.product_price = productDetail.product_price;
            this.product.discount = productDetail.discount;
          } else {
            this.product = null;
          }
          this.snippet.snippet.context.trademark_code = snippetInSlot.context?.trademark_code;
          this.snippet.snippet.context.category_code = snippetInSlot.context?.category_code;
          this.snippet.snippet.context.category_path_by_code = snippetInSlot.context?.category_path_by_code;
          this.snippet.snippet.context.is_include_subcategory = !!snippetInSlot.context?.is_include_subcategory;
          this.originalPositions = await SnippetRestService.getInstance().getPositionCodesForSnippet(this.id);
          this.selectedPositions = [...this.originalPositions];
        }
        this.snippetChanged = false;
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
        window.scrollTo(0, 0);
      } finally {
        this.pageUI.setReady();
      }
    },
    async afterSave() {
      if (this.mode === 'new') {
        await this.$router.push({name: 'snippets'});
      } else {
        this.toaster.success(this.$t('savedSuccessfully'));
        await this.reloadContent();
      }
    },
    setTrademarkCode(trademarkCode?: string | null): void {
      this.snippet.snippet.context.trademark_code = trademarkCode;
      this.onSnippetChanged();
    },
    setCategoryCodeDpManaged(categoryCode?: string | null): void {
      this.snippet.snippet.context.category_code = categoryCode;
      this.onSnippetChanged();
    },
    onSnippetChanged(): void {
      this.snippetChanged = true;
    },
    selectProduct(selectedProducts: CatalogProductOverviewRestDto[]): void {
      if (!selectedProducts?.length) {
        this.product = null;
      } else {
        this.product = selectedProducts[0];
      }
      this.onSnippetChanged();
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .catch(exceptions, true);
    }
  },
});
