
import {defineComponent} from 'vue'
import {UIStateDto} from "@/dtos/UIStateDto";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import BaseContainer from "@/components/UI/Bootstrap/BaseContainer.vue";
import {useToast} from "vue-toastification";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import {AbilityContext} from "@/context/AbilityContext";
import {StatusEnum} from "@/api/enums/status-enum";
import {ValueEnum} from "@/api/enums/value-enum";
import {ContentPageRestService} from "@/services/rest/cms-content-page/ContentPageRestService";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import {PagedContentPageOverviewRestDto} from "@/api/pharma-cms-content-page/models";
import {
  PagedContentPageOverviewRestDtoModel
} from "@/models/api/pharma-cms-content-page/PagedContentPageOverviewRestDtoModel";
import {FullContentPageRestDtoModel} from "@/models/api/pharma-cms-content-page/FullContentPageRestDtoModel";
import {MoveActionEnum} from "@/models/enum/MoveActionEnum";
import CpsOverviewItem from "@/components/layouts/content-pages/overview/CpsOverviewItem.vue";
import {arrayHasContent} from "@/helpers/functions/arrays";

export default defineComponent({
  name: "PriceCardOverview",
  components: {
    BaseContainer,
    BaseTitle,
    CpsOverviewItem,
    AlertError2,
  },
  computed: {
    StatusEnum() {
      return StatusEnum
    },
    AbilityContext() {
      return AbilityContext;
    },
    fullContentPages(): FullContentPageRestDtoModel[] {
      return this.priceCards.results;
    },
    hasContentPages(): boolean {
      return arrayHasContent(this.fullContentPages);
    },
  },
  data: () => ({
    priceCards: PagedContentPageOverviewRestDtoModel.createWithDefaults() as PagedContentPageOverviewRestDto,

    toaster: useToast(),
    pageUI: UIStateDto.createWithDefaults(),
  }),
  mounted() {
    this.reloadContent();
  },
  methods: {
    async reloadContent(): Promise<void> {
      this.pageUI
        .setNotReady()
        .clearError();

      try {
        this.priceCards = await ContentPageRestService.getInstance().findPriceCards();
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.pageUI.setReady();
      }
    },
    async updateStatus(id: string, status: ValueEnum): Promise<void> {
      this.pageUI
        .setNotReady()
        .clearError();

      try {
        await ContentPageRestService.getInstance().updatePageStatusCode(status, id);
        await this.afterSave();
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.pageUI.setReady();
      }
    },
    async deleteContent(id: string): Promise<void> {
      this.pageUI
        .setNotReady()
        .clearError();

      const answer = window.confirm(this.$t('deleteConfirmation'));

      if (answer) {
        try {
          await ContentPageRestService.getInstance().deleteContentPage(id);

          await this.afterSave(this.$t('deletedSuccessfully'));
        } catch (exceptions: any) {
          this.setExceptions(exceptions);
        } finally {
          this.pageUI.setReady();
        }
      }
    },
    async moveContent(id: string, moveAction: MoveActionEnum): Promise<void> {
      this.pageUI
        .setNotReady()
        .clearError();

      try {
        await ContentPageRestService.getInstance().moveContent(id, moveAction);

        await this.afterSave(this.$t('movedSuccessfully'));
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
      }
    },
    async afterSave(message?: string): Promise<void> {
      window.scrollTo(0, 0);
      await handleSavedSuccessfully(message);
      await this.reloadContent();
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .catch(exceptions, true);
    },
  }
})
