import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 col-xl-11 col-xxl-9" }
const _hoisted_3 = { class: "row row-name align-items-center mb-4" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = {
  key: 0,
  class: "col-3"
}
const _hoisted_6 = { class: "row row-status mb-4" }
const _hoisted_7 = { class: "col-6" }
const _hoisted_8 = { class: "d-flex align-items-center" }
const _hoisted_9 = {
  type: "submit",
  class: "btn btn-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTitle = _resolveComponent("BaseTitle")!
  const _component_alert_success = _resolveComponent("alert-success")!
  const _component_alert_error = _resolveComponent("alert-error")!
  const _component_base_spinner = _resolveComponent("base-spinner")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputSelect = _resolveComponent("InputSelect")!
  const _component_dp_form = _resolveComponent("dp-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BaseTitle, {
      title: this.title,
      mode: this.titleMode,
      "back-route": this.goBackRoute,
      mb: 5,
      "help-text": _ctx.$t('descriptions.contentPagesDetailNew')
    }, null, 8, ["title", "mode", "back-route", "help-text"]),
    _createVNode(_component_alert_success, {
      "show-if": this.savedSuccessfully,
      class: "mb-5"
    }, null, 8, ["show-if"]),
    _createVNode(_component_alert_error, {
      exceptions: this.exceptions,
      class: "mb-5"
    }, null, 8, ["exceptions"]),
    (!this.isReady)
      ? (_openBlock(), _createBlock(_component_base_spinner, { key: 0 }))
      : _createCommentVNode("", true),
    (this.isReady)
      ? (_openBlock(), _createBlock(_component_dp_form, {
          key: 1,
          as: "form",
          onSubmit: this.submitData,
          novalidate: ""
        }, {
          default: _withCtx(({ errors }) => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("article", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_InputText, {
                      modelValue: this.contentPage.name,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.contentPage.name) = $event)),
                      label: _ctx.$t('name'),
                      name: "name",
                      rules: "required|max:100",
                      errors: errors
                    }, null, 8, ["modelValue", "label", "errors"])
                  ]),
                  (_ctx.isShowTypeSelector)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createVNode(_component_InputSelect, {
                          modelValue: this.contentPageCriteria.filter_type_code,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.contentPageCriteria.filter_type_code) = $event)),
                          label: _ctx.$t('type'),
                          name: "filterTypeCode",
                          selectText: _ctx.$t('selectText'),
                          options: this.pageTypeEnums,
                          rules: "required",
                          errors: errors
                        }, null, 8, ["modelValue", "label", "selectText", "options", "errors"])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("article", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_InputSelect, {
                        modelValue: this.contentPage.status_code,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((this.contentPage.status_code) = $event)),
                        label: _ctx.$t('status'),
                        name: "statusCode",
                        selectText: _ctx.$t('selectText'),
                        options: this.statusOptions,
                        rules: "required",
                        errors: errors
                      }, null, 8, ["modelValue", "label", "selectText", "options", "errors"])
                    ])
                  ])
                ]),
                _createElementVNode("button", _hoisted_9, _toDisplayString(_ctx.$t('save')), 1)
              ])
            ])
          ]),
          _: 1
        }, 8, ["onSubmit"]))
      : _createCommentVNode("", true)
  ], 64))
}