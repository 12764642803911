
import {defineComponent} from 'vue'
import {mapState} from "pinia";
import {useCustomerStore} from "@/stores/CustomerStore";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {UIStateDto} from "@/dtos/UIStateDto";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import {AbilityContext} from "@/context/AbilityContext";
import {PimProductsOverviewPageLayoutEnum} from "@/models/enum/pim/PimProductsOverviewPageLayoutEnum";
import {arrayHasContent} from "@/helpers/functions/arrays";
import {PimProductsPagingModel} from "@/models/paging/PimProductsPagingModel";
import {
  PagedLaboProductOverviewResultsRestDtoModel
} from "@/models/api/pharma-pim-labo/PagedLaboProductOverviewResultsRestDtoModel";
import {PimLaboProductRestService} from "@/services/rest/pim-labo/PimLaboProductRestService";
import {PimLaboProductsCriteriaModel} from "@/models/criteria/PimLaboProductsCriteriaModel";
import OverviewCriteria from "@/components/layouts/pim/PimLabProductsOverviewCriteria.vue";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import OverviewTiles from "@/components/layouts/pim/PimLabProductsOverviewTiles.vue";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import OverviewTable from "@/components/layouts/pim/PimLabProductsOverviewTable.vue";
import OverviewControls from "@/components/layouts/pim/PimProductsOverviewControls.vue";
import BaseContainer from "@/components/UI/Bootstrap/BaseContainer.vue";
import OverviewLegend from "@/components/layouts/pim/PimProductsOverviewLegend.vue";
import {LaboProductOverviewRestDtoModel} from "@/models/api/pharma-pim-labo/LaboProductOverviewRestDtoModel";
import {default as Offcanvas} from "bootstrap/js/dist/offcanvas";
import {generateDomId} from "@/helpers/functions/string";
import BaseOffcanvas from "@/components/UI/Bootstrap/Offcanvas/BaseOffcanvas.vue";
import PimLabProductsDetail from "@/components/layouts/pim/PimLabProductsDetail.vue";

export default defineComponent({
  name: "PimLabProductsOverview",
  components: {
    PimLabProductsDetail,
    BaseOffcanvas,
    OverviewLegend,
    BaseContainer,
    OverviewControls,
    OverviewTable,
    AlertError2,
    OverviewTiles,
    BaseTitle,
    OverviewCriteria
  },
  data() {
    return {
      offcanvasProductDetail: null as Offcanvas | null,
      offcanvasProductDetailId: generateDomId('offcanvas-product-detail') as string,

      criteriaInitialized: false as boolean,

      productsOverview: PagedLaboProductOverviewResultsRestDtoModel.createWithDefaults(),
      productsOverviewUI: UIStateDto.createWithDefaults(),

      selectedDetailProduct: null as LaboProductOverviewRestDtoModel | null,
    }
  },
  mounted() {
    this.offcanvasProductDetail = new Offcanvas('#' + this.offcanvasProductDetailId);

    if (this.getPimState.pim_labo_products_is_initialized) {
      this.reloadContent();
    } else {
      this.productsOverviewUI.setReady();
    }
  },
  computed: {
    ...mapState(useCustomerStore, ["getCriteria", "getPaging", "getPimState"]),
    AbilityContext() {
      return AbilityContext
    },
    PimProductsOverviewPageLayoutEnum() {
      return PimProductsOverviewPageLayoutEnum;
    },
    productsCriteria(): PimLaboProductsCriteriaModel {
      return this.getCriteria.pim_labo_products;
    },
    productsPaging(): PimProductsPagingModel {
      return this.getPaging.pim_products;
    },
    hasProducts(): boolean {
      return arrayHasContent(this.productsOverview.results);
    }
  },
  methods: {
    onControlsChanged(): void {
      if (this.getPimState.pim_labo_products_is_initialized) {
        this.reloadContent();
      }
    },
    onCriteriaInitialized(): void {
      this.criteriaInitialized = true;
    },
    onCategorizationSubmitted(): void {
    },
    onOpenProductDetail(selectedProduct: LaboProductOverviewRestDtoModel): void {
      this.selectedDetailProduct = selectedProduct;
      this.offcanvasProductDetail?.show();
    },
    async downloadOverviewAsCSV(): Promise<void> {
      try {
        await PimLaboProductRestService.getInstance().exportLaboProductsAsCsv(this.productsCriteria);
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      }
    },
    async reloadContent(): Promise<void> {
      this.productsOverviewUI
        .setNotReady()
        .clearError();

      try {
        this.offcanvasProductDetail?.hide();

        this.productsOverview = await PimLaboProductRestService.getInstance()
          .findLaboProducts(this.productsCriteria, this.productsPaging, this.$i18n.locale);

        this.getPimState.pim_labo_products_is_initialized = true;
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.productsOverviewUI.setReady();
      }
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.productsOverviewUI as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.productsOverviewUI as UIStateDto))
        .catch(exceptions, true);
    }
  }
})
