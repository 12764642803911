import {RestService} from "@/services/rest/RestService";
import {LaboProductAPIApi} from "@/api/pharma-pim-labo";
import {PimProductsPagingModel} from "@/models/paging/PimProductsPagingModel";
import {PimLaboProductsCriteriaModel} from "@/models/criteria/PimLaboProductsCriteriaModel";
import {LaboProductDetailRestDto, PagedLaboProductOverviewResultsRestDto} from "@/api/pharma-pim-labo/models";
import {LaboProductsCriteriaRestDtoModel} from "@/models/api/pharma-pim-labo/LaboProductsCriteriaRestDtoModel";
import {downloadBlobAsFile} from "@/helpers/functions/file";
import {AxiosResponse} from "axios";
import {PhotoRestService} from "@/services/rest/photo/PhotoRestService";

export class PimLaboProductRestService extends RestService {
    private apiService: LaboProductAPIApi;

    protected constructor() {
        super();

        this.apiService = new LaboProductAPIApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().pimUrl);
    }

    public async findLaboProducts(
        labo_products_criteria: PimLaboProductsCriteriaModel,
        products_paging: PimProductsPagingModel,
        language: string
    ): Promise<PagedLaboProductOverviewResultsRestDto> {
        const pp2_products_criteria = new LaboProductsCriteriaRestDtoModel(
            labo_products_criteria,
            products_paging,
            undefined
        );
        const response = await this.apiService
            .findLaboProductsByLaboProductsCriteriaRestDto(pp2_products_criteria);

        await PhotoRestService.getInstance().fillLaboProductImageUrls(response.data.results, language);

        return response.data;
    }

    public async exportLaboProductsAsCsv(labo_products_criteria: PimLaboProductsCriteriaModel, filename: string = 'pim_labo_product_overview.csv'): Promise<void> {
        const pp2_products_criteria = new LaboProductsCriteriaRestDtoModel(
            labo_products_criteria,
            undefined,
            'text/csv'
        );
        const response: AxiosResponse<any, any> = await this.apiService.findLaboProductsByLaboProductsCriteriaRestDto(pp2_products_criteria);

        downloadBlobAsFile(response.data as Blob, filename);
    }

    public async findLaboProductForCnkCode(cnk_code: number, language: string): Promise<LaboProductDetailRestDto> {
        const response = await this.apiService.getLaboProductForCnkCode(cnk_code);

        response.data.product_info.main_image_url = await PhotoRestService.getInstance().getProductImageUrl(response.data.product_info.cnk_code, language);

        return response.data;
    }
}
