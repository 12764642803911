import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "buttons mb-5" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = ["disabled"]
const _hoisted_4 = ["disabled"]
const _hoisted_5 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTitle = _resolveComponent("BaseTitle")!
  const _component_AlertError2 = _resolveComponent("AlertError2")!
  const _component_base_spinner = _resolveComponent("base-spinner")!
  const _component_base_container = _resolveComponent("base-container")!

  return (_openBlock(), _createBlock(_component_base_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_BaseTitle, {
        title: "CSV export",
        mb: 5
      }),
      _createVNode(_component_AlertError2, {
        error: this.exportUI.getError()
      }, null, 8, ["error"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("button", {
          class: "btn btn-primary me-3",
          disabled: this.exportUI.isNotReady(),
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (this.export(_ctx.PimExportTypesEnum.DP_CATEGORIES)), ["prevent"]))
        }, " Exporteer categorieën ", 8, _hoisted_2),
        _createElementVNode("button", {
          class: "btn btn-primary me-3",
          disabled: this.exportUI.isNotReady(),
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (this.export(_ctx.PimExportTypesEnum.DP_CATEGORIES_LEGACY)), ["prevent"]))
        }, " Exporteer categorieën (Farmaframe/Legacy) ", 8, _hoisted_3),
        _createElementVNode("button", {
          class: "btn btn-primary me-3",
          disabled: this.exportUI.isNotReady(),
          onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (this.export(_ctx.PimExportTypesEnum.PRODUCTS_IN_DP_CATEGORIES)), ["prevent"]))
        }, " Exporteer producten met categorieën ", 8, _hoisted_4),
        _createElementVNode("button", {
          class: "btn btn-primary",
          disabled: this.exportUI.isNotReady(),
          onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (this.export(_ctx.PimExportTypesEnum.PRODUCTS_WITH_DP_CATEGORIES)), ["prevent"]))
        }, " Exporteer producten met categorieën (Farmaframe/Legacy) ", 8, _hoisted_5)
      ]),
      _createVNode(_component_base_spinner, {
        spin: this.exportUI.isNotReady()
      }, null, 8, ["spin"])
    ]),
    _: 1
  }))
}