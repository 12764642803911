<template>
  <div
    v-if="visible"
    class="overlay"
  >
    <div
      ref="panel"
      class="floating-panel center-positioned"
      :style="{
        top: `${panelPosition.top}px`,
        left: `${panelPosition.left}px`,
        maxHeight: `${maxHeight}px`,
        minHeight: minHeight ?  `${maxHeight}px` : 'auto',
        overflowY: 'auto'
      }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>

import {nextTick} from "vue";

export default {
  name: "FloatingPanel",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: Number,
      default: 500,
    },
    minHeight: {
      type: Number,
      required: false
    },
  },
  data() {
    return {
      panelPosition: {
        top: 0,
        left: 0,
      },
    };
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.updatePanelPosition();
      }
    },
  },
  methods: {
    async updatePanelPosition() {
      await nextTick(); // Ensure DOM updates are applied
      let left = 0;
      let top = 0;
      const panel = this.$el.firstElementChild;
      // Get panel width to calculate positioning
      const panelWidth = panel?.offsetWidth || 0;
      const viewportWidth = window.innerWidth;

      const viewportHeight = window.innerHeight;
      const panelHeight = panel?.offsetHeight || 0;
      left = (viewportWidth - panelWidth) / 2;
      top = (viewportHeight - panelHeight) / 2;
      this.panelPosition = {top, left};
    },
  },
  mounted() {
    window.addEventListener("resize", this.updatePanelPosition);
    this.updatePanelPosition();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updatePanelPosition);
  },
};
</script>

<style>
.floating-panel {
  position: absolute;
  z-index: 2000;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  padding: 8px;
  border-radius: 8px;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: max-content;
}

.center-positioned {
  position: fixed;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent dark background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1999;
}
</style>
