import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "select-categories" }
const _hoisted_2 = { class: "mas ms-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_modal_button = _resolveComponent("base-modal-button")!
  const _component_SelectCategoriesSelected = _resolveComponent("SelectCategoriesSelected")!
  const _component_ProductCategoriesTree = _resolveComponent("ProductCategoriesTree")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_modal_button, {
      class: _normalizeClass(["", this.btnClass]),
      "modal-id": this.selectCategoriesModalId
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('searchCategory')), 1),
        _createElementVNode("i", _hoisted_2, _toDisplayString(this.btnIcon), 1)
      ]),
      _: 1
    }, 8, ["class", "modal-id"]),
    _createVNode(_component_SelectCategoriesSelected, {
      "selected-product-categories": this.formattedSelectedProductCategories,
      "catalog-customer-code": this.catalogCustomerCode,
      id: "overview",
      onRemoveCategory: this.removeCategory
    }, null, 8, ["selected-product-categories", "catalog-customer-code", "onRemoveCategory"]),
    _createVNode(_component_base_modal, {
      "modal-id": this.selectCategoriesModalId,
      title: _ctx.$t('categories'),
      "min-z-index": 2010,
      "no-footer": ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_SelectCategoriesSelected, {
          "selected-product-categories": this.formattedSelectedProductCategories,
          "catalog-customer-code": this.catalogCustomerCode,
          id: "modal",
          class: "mb-4",
          onRemoveCategory: this.removeCategory
        }, null, 8, ["selected-product-categories", "catalog-customer-code", "onRemoveCategory"]),
        (this.categoriesTree)
          ? (_openBlock(), _createBlock(_component_ProductCategoriesTree, {
              key: 0,
              "categories-tree": this.categoriesTree,
              onSelectCategory: this.addCategory
            }, null, 8, ["categories-tree", "onSelectCategory"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modal-id", "title"])
  ]))
}