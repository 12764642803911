import {CustomerContext} from "@/context/CustomerContext";
import {I18nContext} from "@/context/I18nContext";
import configFile from "../../../public/config.json";

/*
* Generates the url to preview a content page on the SMA
 */
function generateContentPagePreviewUrl(content_page_id: string, expect_hidden_content: boolean = false): string {
    const language: string = I18nContext.getDefaultApiLanguageKey();
    const mainDomain: string = CustomerContext.hasMainDomain()
        ? 'https://' + CustomerContext.getMainDomain()
        : configFile.contentProviderContentPreviewUrl;

    let parameters = '' as string;
    parameters += (expect_hidden_content) ? '?expect_hidden_content=true' : '?expect_hidden_content=false';

    return `${mainDomain}/${language}/_dp/content-page/${content_page_id}/preview${parameters}`
}

function generateContentPagePriceCardPreviewUrl(content_page_id: string, pageSizeCode: string = 'A5'): string {
    const language: string = I18nContext.getDefaultApiLanguageKey();
    const mainDomain: string = CustomerContext.hasMainDomain()
        ? 'https://' + CustomerContext.getMainDomain()
        : configFile.contentProviderContentPreviewUrl;

    let parameters = '' as string;
    parameters += '?page-size-code=' + pageSizeCode;

    return `${mainDomain}/${language}/_dp/content-page/${content_page_id}/price-card.pdf${parameters}`
}

/*
* Generates the url to preview a product filter on the SMA
 */
function generateProductFilterPreviewUrl(product_filter_code: string): string | null {
    const language: string = I18nContext.getDefaultApiLanguageKey();
    const mainDomain: string | null = CustomerContext.hasMainDomain()
        ? 'https://' + CustomerContext.getMainDomain()
        : null;

    if (mainDomain && language === 'nl') {
        // todo implement this url when available in SMA (remove if for language as well)
        // return `${mainDomain}/${language}/_dp/product_filter/${product_filter_code}/preview`;
        return `${mainDomain}/${language}/apotheek/bc/${product_filter_code}`;
    }

    return null;
}

export {
    generateContentPagePreviewUrl,
    generateProductFilterPreviewUrl,
    generateContentPagePriceCardPreviewUrl,
}
