
import {defineComponent, nextTick} from 'vue'
import {UIStateDto} from "@/dtos/UIStateDto";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {CodeDescriptionRestDto} from "@/api/pharma-order-settings/models";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import BaseContainer from "@/components/UI/Bootstrap/BaseContainer.vue";
import {useToast} from "vue-toastification";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import {EnumRestService} from "@/services/rest/enum/EnumRestService";
import {AbilityContext} from "@/context/AbilityContext";
import {EnumClassNameCmsEnum} from "@/models/enum/EnumClassNameCmsEnum";
import {
  PagedSnippetInSlotOverviewRestDtoModel
} from "@/models/api/pharma-cms-snippet/PagedSnippetInSlotOverviewRestDtoModel";
import {PagedSnippetInSlotOverviewRestDto, SnippetInSlotOverviewRestDto} from "@/api/pharma-cms-snippet/models";
import {SnippetRestService} from "@/services/rest/csm-snippet/SnippetRestService";
import {StatusEnum} from "@/api/enums/status-enum";
import {ValueEnum} from "@/api/enums/value-enum";
import SnippetOverviewItem from "@/components/layouts/snippets/SnippetOverviewItem.vue";
import {ContentPageRestService} from "@/services/rest/cms-content-page/ContentPageRestService";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import {SnippetContext} from "@/context/SnippetContext";

export default defineComponent({
  name: "SnippetOverview",
  components: {
    SnippetOverviewItem,
    BaseContainer,
    BaseTitle,
    AlertError2,
  },
  computed: {
    StatusEnum() {
      return StatusEnum
    },
    AbilityContext() {
      return AbilityContext;
    },
    positionsInUse(): CodeDescriptionRestDto[] {
      if (!this.snippetsInSlots.results?.length) {
        return [];
      }

      // Find all positions that are currently in use
      const positionsMap = new Map<string, CodeDescriptionRestDto>();
      for (const snippetInSlot of this.snippetsInSlots.results) {
        positionsMap.set(snippetInSlot.slot_position.code, snippetInSlot.slot_position);
      }

      // Create an array with the positions that are currently in use, in the same order as the lots enum
      const positions: CodeDescriptionRestDto[] = [];
      for (const position of this.slots) {
        if (positionsMap.get(position.code)) {
          positions.push(position);
        }
      }

      return positions;
    },
    activeSnippetsInSlots(): SnippetInSlotOverviewRestDto[] {
      const result: SnippetInSlotOverviewRestDto[] = [];
      for (const snippetInSlot of this.snippetsInSlots.results) {
        if (snippetInSlot.slot_position.code === this.activeTab) {
          result.push(snippetInSlot);
        }
      }
      return result;
    },
  },
  data: () => ({
    activeTab: '' as string,
    slots: [] as CodeDescriptionRestDto[],
    snippetsInSlots: PagedSnippetInSlotOverviewRestDtoModel.createWithDefaults() as PagedSnippetInSlotOverviewRestDto,

    toaster: useToast(),
    pageUI: UIStateDto.createWithDefaults(),
  }),
  mounted() {
    this.reloadContent();
  },
  methods: {
    async reloadContent(): Promise<void> {
      const isSlotPositionInUse = (code: string) => {
        return this.positionsInUse.filter((value: CodeDescriptionRestDto) => value.code === code).length > 0;
      };

      this.pageUI
        .setNotReady()
        .clearError();

      try {
        this.slots = await EnumRestService.getInstance().getCmsEnumValuesByClassname(EnumClassNameCmsEnum.SNIPPET_SLOT_POSITION);
        this.snippetsInSlots = await SnippetRestService.getInstance().findSnippetsInSlot();

        await nextTick();

        if (isSlotPositionInUse(SnippetContext.getSelectedSlotPosition())) {
          this.showTab(SnippetContext.getSelectedSlotPosition());
        } else if (this.positionsInUse.length > 0) {
          this.showTab(this.positionsInUse[0].code);
        } else {
          this.activeTab = '';
        }
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.pageUI.setReady();
      }
    },
    goToDetail(snippetInSlotId: string): object {
      return {
        name: 'edit-snippet-wysiwyg',
        params: {
          id: snippetInSlotId,
        }
      };
    },
    showTab(code: string) {
      SnippetContext.setSelectedSlotPosition(code);
      this.activeTab = code;
    },

    async copySnippet(page_id: string): Promise<void> {
      this.pageUI
        .setNotReady()
        .clearError();

      try {
        await SnippetRestService.getInstance().copySnippet(page_id);

        await this.afterSave(this.$t('copiedSuccessfully'));
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
      } finally {
        this.pageUI.setReady();
      }
    },
    async deleteSnippet(id: string): Promise<void> {
      const answer = window.confirm(this.$t('deleteSnippetConfirmation'));

      if (answer) {
        this.pageUI
          .setNotReady()
          .clearError();

        try {
          await SnippetRestService.getInstance().deleteSnippet(id);

          await this.afterSave(this.$t('deletedSuccessfully'));
        } catch (exceptions: any) {
          this.setExceptions(exceptions);
        } finally {
          this.pageUI.setReady();
        }
      }
    },
    async updateStatus(id: string, status: ValueEnum): Promise<void> {
      this.pageUI
        .setNotReady()
        .clearError();

      try {
        await ContentPageRestService.getInstance().updatePageStatusCode(status, id);
        SnippetRestService.getInstance().snippetsChanged();
        await this.afterSave();
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.pageUI.setReady();
      }
    },
    async afterSave(message?: string): Promise<void> {
      window.scrollTo(0, 0);
      await handleSavedSuccessfully(message);
      await this.reloadContent();
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .catch(exceptions, true);
    },
  }
})
