import {
    FullContentPageRestDto,
    PagedContentPageOverviewRestDto,
    PagingMetadataRestDto
} from "@/api/pharma-cms-content-page/models";

export class PagedContentPageOverviewRestDtoModel implements PagedContentPageOverviewRestDto {
    results: FullContentPageRestDto[];
    paging_metadata: PagingMetadataRestDto;

    constructor() {
        this.results = [];
        this.paging_metadata = {} as PagingMetadataRestDto;
    }

    public static createWithDefaults(): PagedContentPageOverviewRestDtoModel {
        return new this();
    }
}
