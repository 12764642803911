
import {defineComponent} from "vue";
import {Exception} from "@/api/interfaces";
import {mapActions, mapGetters} from "vuex";
import {CodeDescriptionRestDto, ContentPageRestDto} from "@/api/pharma-cms-content-page/models";
import {StatusEnum} from "@/api/enums/status-enum";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {ContentPageCriteriaModel} from "@/models/criteria/ContentPageCriteriaModel";
import {mapState} from "pinia";
import {useCustomerStore} from "@/stores/CustomerStore";
import {CodeDescriptionRestDtoModel} from "@/models/CodeDescriptionRestDtoModel";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import InputText from "@/components/UI/InputText.vue";
import InputSelect from "@/components/UI/InputSelect.vue";
import {PageDetailModeEnum} from "@/models/enum/PageDetailModeEnum";

export default defineComponent({
  name: "CpsDetail",
  components: {InputSelect, InputText, BaseTitle},
  props: {
    id: {
      type: String,
    },
    libCode: {
      type: String,
      required: false,
    },
    mode: {
      type: String,
      required: true,
    },
    backRoute: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      statusOptions: [
        new CodeDescriptionRestDtoModel(StatusEnum.PUBLISHED, this.$t('published')),
        new CodeDescriptionRestDtoModel(StatusEnum.NOT_PUBLISHED, this.$t('notPublished'))
      ] as CodeDescriptionRestDtoModel[],

      exceptions: [] as Exception[],
      isReady: false as boolean,
      savedSuccessfully: false as boolean,

      customerCodeCopyTo: null as string | null,
    };
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    PageDetailModeEnum() {
      return PageDetailModeEnum
    },
    ...mapGetters("cms_content_page", [
      "getContentPage",
      "getContentPageLibraryType",
      "getPageTypeEnums",
      "getContentPageLibraryName"
    ]),
    ...mapState(useCustomerStore, ["getCriteria"]),
    contentPage(): ContentPageRestDto {
      return this.getContentPage.cp;
    },
    contentPageCriteria(): ContentPageCriteriaModel {
      return this.getCriteria.content_page;
    },
    libraryType(): string | undefined {
      if (this.isLibrary) {
        return this.getContentPageLibraryType(this.libCode);
      }
      return undefined;
    },
    pageTypeEnums(): CodeDescriptionRestDto[] {
      return this.getPageTypeEnums;
    },
    titleMode(): string {
      if (this.mode === PageDetailModeEnum.NEW_PRICE_CARD) {
        return PageDetailModeEnum.NEW;
      }
      return this.mode;
    },
    title(): string {
      if (this.isLibrary) {
        return `${this.$t('websiteContent')} ${this.$t('library').toLowerCase()} (${this.getContentPageLibraryName(this.libCode)})`;
      }
      if (this.mode === PageDetailModeEnum.NEW_PRICE_CARD) {
        return this.$t('priceCard');
      }
      return this.$t('websiteContent');
    },
    isLibrary(): boolean {
      return !!this.libCode;
    },
    isShowTypeSelector(): boolean {
      return !this.isLibrary && this.mode !== PageDetailModeEnum.NEW_PRICE_CARD;
    },
    goBackRoute(): object {
      if (this.isLibrary) {
        return {
          name: 'libraries-content-pages-overview',
          params: {page_type_code: this.$route.params.page_type_code, lib_code: this.$route.params.lib_code}
        };
      }
      return {name: this.backRoute};
    },
  },
  methods: {
    ...mapActions('cms_content_page', [
      "clearContentPage",
      "createContentPage",
      "searchPageTypeEnums"
    ]),
    async reloadContent(): Promise<void> {
      this.isReady = false;
      this.exceptions = [];

      try {
        await this.searchPageTypeEnums(this.isLibrary);

        this.clearContentPage();
        this.contentPageCriteria.filter_type_code = (
          (this.isLibrary)
            ? this.libCode
            : this.contentPageCriteria.filter_type_code ?? null
        ) ?? null;
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
      } finally {
        this.isReady = true;
      }
    },
    async submitData(): Promise<void> {
      this.exceptions = [];

      try {
        if (this.isLibrary) {
          await this.createContentPage({
            type_code: this.libraryType,
            library_code: this.libCode,
          });
        } else if (this.mode === PageDetailModeEnum.NEW_PRICE_CARD) {
          await this.createContentPage({
            type_code: 'PAGE_PRICE_CARD'
          });
        } else {
          await this.createContentPage({
            type_code: this.contentPageCriteria.filter_type_code
          });
        }

        await handleSavedSuccessfully();
        await this.$router.push(this.goBackRoute);
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
      }
    },
    setExceptions(exceptions: Exception[]): void {
      this.exceptions = exceptions;
    },
  },
});
