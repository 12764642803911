import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "products-list mt-5" }
const _hoisted_2 = { class: "mb-4" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-xxl-8 col-xl-12 mb-4" }
const _hoisted_5 = { class: "d-inline-block mt-4 pt-4 border-top border-2 border-light" }
const _hoisted_6 = {
  type: "submit",
  class: "btn btn-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cb_form_text_with_title = _resolveComponent("cb-form-text-with-title")!
  const _component_alert_success = _resolveComponent("alert-success")!
  const _component_alert_error = _resolveComponent("alert-error")!
  const _component_base_spinner = _resolveComponent("base-spinner")!
  const _component_input_cnk_list = _resolveComponent("input-cnk-list")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_dp_form = _resolveComponent("dp-form")!
  const _component_base_container = _resolveComponent("base-container")!

  return (_openBlock(), _createBlock(_component_base_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_cb_form_text_with_title, {
        title: _ctx.$t('productsInTheSpotlight'),
        description: _ctx.$t('descriptionProductsInTheSpotlight') + ' ' + _ctx.$t('descriptionProductsInTheSpotlight2'),
        "can-fallback": "",
        "cb-type": _ctx.filterCode,
        mode: "edit",
        "textarea-height": 200
      }, null, 8, ["title", "description", "cb-type"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t('productsList')), 1),
          _createVNode(_component_alert_success, {
            "show-if": _ctx.savedSuccessfully,
            class: "mt-3"
          }, null, 8, ["show-if"]),
          _createVNode(_component_alert_error, {
            exceptions: _ctx.exceptions,
            class: "mt-3"
          }, null, 8, ["exceptions"])
        ]),
        (!_ctx.isReady)
          ? (_openBlock(), _createBlock(_component_base_spinner, { key: 0 }))
          : (_openBlock(), _createBlock(_component_dp_form, {
              key: 1,
              as: "form",
              onSubmit: _ctx.submitData,
              novalidate: ""
            }, {
              default: _withCtx(({ errors }) => [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_input_cnk_list, {
                      modelValue: _ctx.productsInTheSpotlight,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.productsInTheSpotlight) = $event)),
                      height: 150,
                      name: _ctx.$t('productsList'),
                      errors: errors
                    }, null, 8, ["modelValue", "name", "errors"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("button", _hoisted_6, _toDisplayString(_ctx.$t('save')), 1),
                  _createVNode(_component_router_link, {
                    class: "ms-3 btn btn-outline-primary",
                    to: "/"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('cancel')), 1)
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            }, 8, ["onSubmit"]))
      ])
    ]),
    _: 1
  }))
}