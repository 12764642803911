import {RestService} from "@/services/rest/RestService";
import {CacheService} from "@/services/cache/CacheService";
import {CacheKeyEnum} from "@/services/cache/CacheKeyEnum";
import {CustomerContext} from "@/context/CustomerContext";
import {ImageAPIApi} from "@/api/pharma-photo-product-image";
import {ProductImageResponseRestDto, ProductImagesRequestRestDto} from "@/api/pharma-photo-product-image/models";
import {CatalogProductOverviewRestDtoModel} from "@/models/api/pharma-cpc-product-mgmt/CatalogProductOverviewRestDtoModel";
import {trimLeadingZeroes} from "@/helpers/functions/number";
import {MultilingualFieldRestDto, Pp2ProductOverviewRestDto} from "@/api/pharma-pim-pp2/models";
import {LaboProductOverviewRestDto} from "@/api/pharma-pim-labo/models";
import {SelectableBrandRestDto, SelectableProductRestDto} from "@/api/pharma-cpc-mgmt/models";

export class PhotoRestService extends RestService {

    private imageApiService: ImageAPIApi;

    private static readonly NOT_FOUND_URL: string = 'https://cdn.dp2.digital-pharma.eu/assets/img/blancoverpakking.jpg';

    protected constructor() {
        super();

        this.imageApiService = new ImageAPIApi(this.getDpAxiosInstance(), undefined, this.getConfig().photoUrl);
    }

    public async getProductImageUrl(cnkCode: number,
                                    language: string): Promise<string> {
        const trimmedCnkCode = trimLeadingZeroes('' + cnkCode);
        const cnkCodeMap = await this.getImageUrls([trimmedCnkCode], language);
        // @ts-ignore
        return cnkCodeMap.get(trimmedCnkCode);
    }

    public async fillProductImageUrls(
        products: CatalogProductOverviewRestDtoModel[],
        language: string
    ): Promise<void> {
        const cnkCodes: string[] = products
            .filter((product) => product.product_info.cnk_code)
            .map((product) => trimLeadingZeroes(product.product_info.cnk_code ?? ''));

        const cnkCodeMap: Map<string, string> = await this.getImageUrls(cnkCodes, language);

        products
            .filter((product) => product.product_info.cnk_code)
            .map((product) => {
                // @ts-ignore
                product.main_image_url = cnkCodeMap.get(trimLeadingZeroes(product.product_info.cnk_code));
            });
    }

    public async fillPimProductImageUrls(products: Pp2ProductOverviewRestDto[]): Promise<void> {
        const cnkCodes: string[] = products
            .filter((product) => product.product_info.cnk_code)
            .map((product) => '' + product.product_info.cnk_code);

        let cnkCodeMap: Map<string, string> = await this.getImageUrls(cnkCodes, 'nl');

        products
            .filter((product) => product.product_info.cnk_code)
            .map((product) => {
                if (!product.product_info.main_image_url) {
                    product.product_info.main_image_url = {};
                }
                product.product_info.main_image_url.nl = cnkCodeMap.get('' + product.product_info.cnk_code);
            });

        cnkCodeMap = await this.getImageUrls(cnkCodes, 'fr');

        products
            .filter((product) => product.product_info.cnk_code)
            .map((product) => {
                if (!product.product_info.main_image_url) {
                    product.product_info.main_image_url = {};
                }
                product.product_info.main_image_url.fr = cnkCodeMap.get('' + product.product_info.cnk_code);
            });

        cnkCodeMap = await this.getImageUrls(cnkCodes, 'de');

        products
            .filter((product) => product.product_info.cnk_code)
            .map((product) => {
                if (!product.product_info.main_image_url) {
                    product.product_info.main_image_url = {};
                }
                product.product_info.main_image_url.de = cnkCodeMap.get('' + product.product_info.cnk_code);
            });

        cnkCodeMap = await this.getImageUrls(cnkCodes, 'en');

        products
            .filter((product) => product.product_info.cnk_code)
            .map((product) => {
                if (!product.product_info.main_image_url) {
                    product.product_info.main_image_url = {};
                }
                product.product_info.main_image_url.en = cnkCodeMap.get('' + product.product_info.cnk_code);
            });
    }

    public async fillLaboProductImageUrls(
        products: LaboProductOverviewRestDto[],
        language: string
    ): Promise<void> {
        const cnkCodes: string[] = products
            .filter((product) => product.product_info.cnk_code)
            .map((product) => '' + product.product_info.cnk_code);

        const cnkCodeMap: Map<string, string> = await this.getImageUrls(cnkCodes, language);

        products
            .filter((product) => product.product_info.cnk_code)
            .map((product) => {
                // @ts-ignore
                product.product_info.main_image_url = cnkCodeMap.get('' + product.product_info.cnk_code);
            });
    }

    public async fillSelectableProductImageUrls(
        products: SelectableProductRestDto[],
        language: string
    ): Promise<void> {
        const cnkCodes: string[] = products
            .filter((product) => product.cnk_code)
            .map((product) => trimLeadingZeroes(product.cnk_code ?? ''));

        const cnkCodeMap: Map<string, string> = await this.getImageUrls(cnkCodes, language);

        products
            .filter((product) => product.cnk_code)
            .map((product) => {
                // @ts-ignore
                product.main_image_url = cnkCodeMap.get(trimLeadingZeroes(product.cnk_code));
            });
    }

    private async getImageUrls(cnkCodes: string[], language: string): Promise<Map<string, string>> {
        const customerCode = CustomerContext.getCustomerCode();
        const cacheKeyPrefix: string = CacheKeyEnum.PRODUCT_IMAGE_URL
            + '.' + (language ?? '--')
            + '.';

        const missingUrlCnkCodes: string[] = [];
        const resultMap: Map<string, string> = new Map();

        cnkCodes.map(cnkCode => {
            const url: string = CacheService.getInstance().get(
                cacheKeyPrefix + cnkCode,
                customerCode
            );
            if (url) {
                resultMap.set(cnkCode, url);
            } else {
                missingUrlCnkCodes.push(cnkCode);
            }

        })

        if (!missingUrlCnkCodes.length) {
            return resultMap;
        }

        const productImagesRequestDto: ProductImagesRequestRestDto = {
            language: language,
            cnk_codes: missingUrlCnkCodes
        };

        const response = await this.imageApiService.findManyMainProductImage(productImagesRequestDto);
        const results: Array<ProductImageResponseRestDto> = response.data;
        if (results) {
            for (const imageResponseDto of results) {
                const url: string = imageResponseDto.url ?? PhotoRestService.NOT_FOUND_URL;
                if (imageResponseDto.cnk_code) {
                    const trimmedCnKCode = trimLeadingZeroes(imageResponseDto.cnk_code);
                    CacheService.getInstance().set(
                        cacheKeyPrefix + trimmedCnKCode,
                        url,
                        customerCode,
                        58 * 60_000 /* URLs are valid for 60 minutes, so cache them for 58 minutes */
                    );
                    resultMap.set(trimmedCnKCode, url);
                }
            }
        }

        return resultMap;
    }
}
